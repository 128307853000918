
<template>
<div class="navDiv">
    <el-menu
    router
        :default-active="defaultPage"
        mode="horizontal"
        class="el-menu-demo mainWidth"

        style="height:40px;padding-top:10px"
     >
        <el-menu-item index="/home" ><span class="iconDot"></span> 首页</el-menu-item>
        <el-submenu index="1" class="submenuDiv">
            <template slot="title">
               <span class="iconDot"></span> 
               关于我们
            </template>
            <el-menu-item index="/aboutCompany"  >
                公司介绍
            </el-menu-item>
            <el-menu-item index="/aboutNews" >
                新闻动态
            </el-menu-item>
            <el-menu-item index="/aboutQualify" >
                产品资质
            </el-menu-item>
            <el-menu-item index="/aboutMember" >
                招贤纳士
            </el-menu-item>
        </el-submenu>
        <el-submenu index="2" class="submenuDiv">
            <template slot="title">
               <span class="iconDot"></span>  产品中心
            </template>
            <el-menu-item index="/productResearch/self">自研产品</el-menu-item>
            <el-menu-item index="/productResearch/agent">代理产品</el-menu-item>
        </el-submenu>
        <el-submenu index="3" class="submenuDiv">
            <template slot="title"><span class="iconDot"></span> 解决方案</template>
            <el-menu-item v-for="(item,i) in solutionList" :index="item.value" :key="i">{{item.name}}</el-menu-item>
            <div class="el-menu-item" @click.stop="gopath">EHS环境健康安全方案</div>
            <!-- <el-menu-item index="/solutionView/hlwbi">互联网BI</el-menu-item>
            <el-menu-item index="/solutionView/dzdsj">地质大数据</el-menu-item>
            <el-menu-item index="/solutionView/qlcjznjc">桥梁沉降智能监测</el-menu-item>
            <el-menu-item index="/solutionView/zhyyspai">智慧医院视频AI</el-menu-item>
            <el-menu-item index="/solutionView/zhlyszls">智慧楼宇数字孪生</el-menu-item>
            <el-menu-item index="/solutionView/zhgdrydw">智慧工地人员定位</el-menu-item>
            <el-menu-item index="/solutionView/zhgdwzdw">智慧工地物资定位</el-menu-item> -->
        </el-submenu>
        <!-- <el-submenu index="5" class="submenuDiv">
            <template slot="title"><span class="iconDot"></span> 成功案例</template>
                <el-menu-item index="/exampleMember">合作客户</el-menu-item>
                <el-menu-item index="/exampleSay">客户感言</el-menu-item>
        </el-submenu> -->
        <el-menu-item index="/exampleMember" ><span class="iconDot"></span> 成功案例</el-menu-item>
        <el-menu-item style="padding-left:24px;" index="/aboutContact" ><span class="iconDot"></span> 联系我们</el-menu-item>
    </el-menu>
   
</div>
</template>

<script>
export default {
    name:"AppNav",
    data(){
        return{
            defaultPage:"home",
            solutionList:[{name:"供应商风控大数据",value:"/solutionView/gysfkdsj"},
                {name:"金融风控大数据",value:"/solutionView/jrfkdsj"},
                {name:"基础设施监测",value:"/solutionView/jcssjc"},
                {name:"视频结构分析",value:"/solutionView/spjgfx"},
                {name:"财务RPA",value:"/solutionView/cwrpa"},
                // {name:"EHS环境健康安全方案",value:""},
                {name:"BIM数据可视化和数字孪生",value:"/solutionView/bimsj"}
               ]
        }
    },
    methods:{
        init(){
            let name=this.$route.path

            this.defaultPage=name
        }
    },
    watch: {
            $route() {
                this.init()  //监听路由变化
            }
        },
    created(){
        this.init()
    },
    methods:{
        gopath(){
            window.open('http://ehs.couponcad.com/')
        }
    }
}
</script>
<style scoped>
.textAlginLeft{
    text-align: left;
    
}
.navDiv{
    height: 60px;
    min-width:680px
}
.iconDot{
    display:none;
    width:4px;
    height:4px;
    background-color:#662299;
}
</style>