
<template>
<div class="headerDiv">
   <el-row  class="mainWidth">
       <el-col  :span=4>
           <div  class="grid-content" style="padding-left:20px" ><img src="../../static/logo.png"  style="vertical-align: middle;"/></div>
       </el-col>
       <el-col :span="12">
           <head-nav></head-nav>
       </el-col>
       
        <el-col :span=4>
            <div  class="grid-content ">
                 <el-input size="mini" placeholder="请输入关键词搜索" suffix-icon="el-icon-search" v-model="inputSearch"></el-input>
            </div>
       </el-col>
       <el-col :span=4>
            <div  class="grid-content headerDiv3" style="text-align:right">
                <span><img src="../../static/tel.png"  style="vertical-align: middle;"/> </span>
                <span> 售前咨询: </span>
                <span> 010-8435-1553</span>
            </div>
       </el-col>
       
   </el-row>
</div>
</template>

<script>
import HeadNav from './nav.vue'
export default {
    name:"AppHeader",
     components:{
        HeadNav
    },
    data(){
        return{
            username:"",
            inputSearch:""
        }
    },
    methods:{
      
    },
    created(){
        this.username=this.Util.getStorage("username")
    }
}
</script>
<style >
.headerDiv{
    color:#000;
}
.grid-content{
    border-radius: 4px;
    height: 60px;
    line-height:60px;
}
.font30{
    font-size: 24px;
}
.userDiv{
    float:right;

}
.headerDiv3 span{
    font-size: 14px;
    margin-left:5px;
    margin-right:5px;
}
</style>