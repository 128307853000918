
<template>
<div>
    <div class="footerDiv">
    <div class="footerContentDiv">
        <el-row>
            <el-col :span=12 class="div1">
                <p>专业咨询了解更多</p>
                <span style="font-size:14px">更多解决方案，寻找专业团队，请联系我们</span>
            </el-col>
            <el-col :span=12 class="div2">
                <p>销售咨询：010-84351553</p>
                <el-button size="mini" class="btn btn-default" @click="turnToContact">立即咨询</el-button>
            </el-col>
        </el-row>
    </div>
    </div>

    <div class="footerBottomDiv">
        <div class="footerContentDiv">
            <el-row>
                <el-col :span="5">
                    <div class="fTitle">解决方案</div>
                    <p>
                        <router-link :to="{name: 'solutionView', params: { type: 'gysfkdsj' }}">供应商风控大数据</router-link>
                    <!-- <a href="/solutionView/gysfkdsj">供应商风控大数据</a> -->
                    </p>
                    <!-- <p><a href="/solutionView/jrfkdsj">金融风控大数据</a></p>
                    <p><a href="/solutionView/jcssjc">基础设施监测</a></p>
                    <p><a href="/solutionView/spjgfx">视频结构分析</a></p>
                    <p><a href="/solutionView/cwrpa">财务RPA</a></p>
                    <p><a href="/solutionView/zzyrpa">制造业RPA</a></p>
                    <p><a href="/solutionView/bimsj">BIM数据可视化和数字孪生</a></p> -->
                    <p><router-link :to="{name: 'solutionView', params: { type: 'jrfkdsj' }}">金融风控大数据</router-link></p>
                    <p><router-link :to="{name: 'solutionView', params: { type: 'jcssjc' }}">基础设施监测</router-link></p>
                    <p><router-link :to="{name: 'solutionView', params: { type: 'spjgfx' }}">视频结构分析</router-link></p>
                    <p><router-link :to="{name: 'solutionView', params: { type: 'cwrpa' }}">财务RPA</router-link></p>
                    <p><router-link :to="{name: 'solutionView', params: { type: 'zzyrpa' }}">制造业RPA</router-link></p>
                    <p><router-link :to="{name: 'solutionView', params: { type: 'bimsj' }}">BIM数据可视化和数字孪生</router-link></p>
                </el-col>
                <el-col :span="3">
                    <div  class="fTitle">产品中心</div>
                    <!-- <p><a href="/productResearch/self">自研产品</a></p>
                    <p><a href="/productResearch/agent">代理产品</a></p> -->
                    <p><router-link :to="{name: 'productResearch', params: { type: 'self' }}">自研产品</router-link></p>
                    <p><router-link :to="{name: 'productResearch', params: { type: 'agent' }}">代理产品</router-link></p>
                </el-col>
                <el-col :span="3">
                    <div  class="fTitle">成功案例</div>
                    <!-- <p><a href="/exampleMember">合作客户</a></p>
                    <p><a href="/exampleSay">客户感言</a></p> -->
                    <p><router-link :to="{name: 'exampleMember'}">合作客户</router-link></p>
                    <p><router-link :to="{name: 'exampleSay'}">客户感言</router-link></p>
                </el-col>
                <el-col :span="3">
                    <div  class="fTitle">关于我们</div>
                    <!-- <p><a href="/aboutCompany">公司介绍</a></p>
                    <p><a href="/aboutNews">新闻动态</a></p>
                    <p><a href="/aboutQualify">产品资质</a></p>
                    <p><a href="/aboutMember">招贤纳士</a></p> -->
                    <p><router-link :to="{name: 'aboutCompany'}">公司介绍</router-link></p>
                    <p><router-link :to="{name: 'aboutNews'}">新闻动态</router-link></p>
                    <p><router-link :to="{name: 'aboutQualify'}">产品资质</router-link></p>
                    <p><router-link :to="{name: 'aboutMember'}">招贤纳士</router-link></p>
                </el-col>
                <el-col :span="5">
                    <div  class="fTitle">联系我们</div>
                    <p>客服邮件</p>
                    <p>zhengfan.yang@couponcad.com</p>
                    <p>联系电话:010-84351553</p>
                </el-col>
                <el-col :span="4" style="padding-top:20px">
                    <img src="../../static/erweima5.png" style="width:100%"/>
                </el-col>
            </el-row>
        </div>
    </div>
    <div style="text-align:center;">
       <strong> Copyright &copy; 2007 酷鹏凯迪北京科技有限公司 . All rights reserved. <a href="http://www.beian.miit.gov.cn" style="color:#3388e2">京ICP备17010263号</a></strong>
    </div>
</div>

</template>

<script>
export default {
    name:"AppFooter",
    data(){
        return{
        }
    },
    methods:{
      turnToContact(){
          this.$router.push({
              name:"aboutContact"
          })
      }
    },
    created(){
    }
}
</script>
<style >
.footerDiv{
    width:100%;
    background-image: url(../../static/bg1.png);
}
.footerContentDiv{
    width:70%;
    margin:0 auto;
    color:#fff;
    padding: 40px 0;
}
.footerBottomDiv{
    width:100%;
    background-color:#212330
    /* #1a1616; */
}
.footerContentDiv p{
    font-size:14px;
    color:gray
}
.footerContentDiv a{
    font-size:14px;
    color:gray
}
.footerContentDiv a:hover{
    color:#d9d9d9
}
.footerContentDiv .fTitle{
    font-size:16px;
    color:#fff;
    margin:40px 0;
}
.footerContentDiv .div1 p{
    font-size: 24px;
    line-height: 24px;
    color: #fff;
}
.footerContentDiv .div2 p{
    font-size: 22px;
    line-height: 22px;
    color: #fff;
}
</style>