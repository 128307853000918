<template>
  <div id="index">
    <el-container>
      <el-header   style="padding:0">
        <app-header ></app-header>
      </el-header>
      <el-container style="overflow:hidden; zoom:1;margin-top:10px;background:#fff"  :style="navHeight">
       <router-view/>
      </el-container>
       <el-footer style="padding:0">
         <app-footer></app-footer>
       </el-footer>
    </el-container>
  </div>
</template>


<script>
  import AppHeader from '@/components/common/header'
  import AppFooter from '@/components/common/footer'
  export default {
    name: 'index',
    components:{
     AppHeader,AppFooter
    },
    data(){
          return {
                levelList: [],
                navHeight:{},
                mainHeight:0
            }
    },
    methods:{
      init(){
      },
       getBreadcrumb() {
          let matched = this.$route.meta  //获取路由信息，并过滤保留路由名称信息存入数组
          let name=this.$route.name
          if(name=="clusterDetail"){
            matched.title="集群管理-"+this.$route.params.name
          }
          this.levelList = [matched]
          // console.log(matched)
      }
    },
    // watch: {
    //         $route() {
    //             this.getBreadcrumb()  //监听路由变化
    //         }
    //     },
    created(){
        // this.getBreadcrumb()
        
    },
    mounted(){
      var clientHeight = document.documentElement.clientHeight;
         var navh=(clientHeight-120)+"px"
        this.navHeight={'min-height':navh}
    //  this.init()
    },
  };
</script>

<style lang="less">
#index {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
 
  color: #2c3e50;

}
a{
  color:#303133;
  text-decoration: none;
}
.el-menu-item.is-active a{
  color:#409EFF;
}
.breadCrumDiv{
  position:absolute;
  top:120px;
  padding-bottom:10px;
  padding-left:10px;

}
.asideDiv{
  margin-left:20px;
  background:#fff;
}
.mainDiv{
  margin-left:10px;
  margin-right:20px;
  margin-bottom:10px;
  background:#fff;
}
</style>
